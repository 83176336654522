import React, { useState, useEffect } from "react"
import styles from "./tyc.module.scss"
import Layout from "../Layout"
import SvgIcon from "../SvgIcon"
import { IsMobileDevice } from "../../../utils"

const TyC = () => {
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  })

  return (
    <div className={styles.tyc}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "3%",
          marginBottom: "2%",
        }}
      >
        <SvgIcon
          name="micro-mouth-icon"
          size={isMobile ? "23.010vw" : "6.232vw"}
          // className={styles.mouthIcon}
        />
      </div>
      <Layout type="secondary" noBudIcon noFooter>
        <div className={styles.tycContainer}>
          <h1>BASES Y CONDICIONES</h1>
          <p>
            Promo “BUD Stage”
            <br />
            1.- Aceptación de Bases y Condiciones: La presente acción
            promocional denominada “BUD STAGE” (en adelante, la “Promoción”) es
            organizada por Cervecería y Maltería Quilmes S.A.I.C.A. y G, CUIT
            33-50835825-9, con domicilio constituido a los efectos de la
            presente Promoción en la calle Charcas 5160, Ciudad de Buenos Aires
            (en adelante, el “Organizador”) para su marca Budweiser. Las
            personas intervinientes en esta Promoción por su sola participación
            aceptan de pleno derecho y de forma incondicional todas y cada una
            de las disposiciones descriptas en estas bases y condiciones (en
            adelante, las “Bases”).
            <br />
            2.- Vigencia: La presente Promoción tendrá vigencia únicamente en la
            Provincia de Buenos Aires, específicamente en las localidades
            comprendidas dentro de la Ciudad Autónoma de Buenos Aires y Gran
            Buenos Aires (en adelante, el “Territorio”), desde el 10/5/2022 a
            las 9:00 hasta las 00:00 del 14/6/2022 (en adelante, el “Plazo de
            Vigencia”).
            <br />
            3.- Participantes: Podrán participar en la presente Promoción todas
            las personas humanas que al momento de la entrada en vigencia de la
            Promoción sean mayores de 18 años y que residan en el Territorio,
            completen el formulario de participación con sus datos personales y
            cumplan la consigna que más adelante se detalla (en adelante, el/los
            “Participantes”).
            <br />
            4.- Exclusión: No podrán participar los empleados del Organizador,
            ni los empleados ni personal directivo de las agencias de publicidad
            y promociones, y demás proveedores vinculados a la presente
            Promoción, ni sus parientes hasta el segundo grado de consanguinidad
            y primero de afinidad. Esta restricción asimismo aplicará a los
            empleados desvinculados de las empresas mencionadas precedentemente,
            dentro de los treinta (30) días del comienzo de la Promoción.
            <br />
            5.- Mecánicas y selección de ganadores: Aquellos interesados en
            participar deberán completar el formulario para participar con los
            siguientes datos: Nombre y apellido, correo electrónico, fecha de
            nacimiento, provincia, cuenta de Instagram personal. A su vez, luego
            de completar el formulario deberán optar por una de las dos
            mecánicas de participación: a) grabar una canción de su autoría en
            fromato video y subirlo a la landing de la Promoción, o b) votar una
            o más canciones y participar del sorteo entre todos los votantes.
            <br />
            a) Grabación de la canción: los interesados en participar bajo esta
            modalidad podrán subir a la landing del Organizador una canción de
            su propia autoría (letra y música) dentro de los géneros Rock, Pop,
            Rap, Indie y R&B. Para poder subirlo, el video deberá grabarse en
            formato video y subirlo, junto con la siguiente información que se
            le solicitará: nombre de artista y canción. Entre todos los que
            cumplan con la consigna de subir el video con su tema se
            seleccionará, mediante un jurado que estará conformado por Meme
            Bouquet (Dj/Artista), Renata Repetto (Artista/Influencer) y el
            público. El video que más votos haya recibido y haya sido elegido
            por el jurado será el ganador del premio que más adelante se
            detalla. La carga de videos en el sitio estará habilitada desde el
            desde el 03/05/2022 a las 9:00 hasta las 00:00 del 27/05/2022 (en
            adelante, el “Plazo de Vigencia de carga de videos”).
            <br />
            b) Sorteo entre los votantes: Entre todos los participantes que
            hayan completado el formulario y que hayan efectuado una votación a
            al menos uno de los participantes, pudiendo emitir hasta un voto por
            canción, pero no más de un voto a cada uno de los videos subidos.
            Luego de la votación deberá contestar una pregunta sobre ocasión de
            consumo. Entre todos estos participantes, se llevará a cabo un
            sorteo al azar el día 5 de julio de 2022, a las 12,00 hs. en el
            domicilio del Organizador, donde se seleccionará un potencial
            ganador y un potencial ganador suplente.
            <br />
            6.- Programa de Premios: El participante cuya canción haya sido
            seleccionado accederá en concepto de premio a una invitación a tocar
            como telonero del artista que se presente en la noche Budweiser de
            Camping BA dentro de los 60 días de la selección como ganador
            (Avenida del Libertador y Callao, CABA) en el horario que disponga
            el Organizador. El premio no incluye ninguna contraprestación
            adicional como traslados, alimentación ni alojamiento.
            <br />
            Por su parte, quien haya sido seleccionado como potencial ganador en
            el sorteo, por haber votado, podrá acceder a un pase anual para
            asistir a eventos de Camping BA ( pase anual constituido por dos (2)
            entradas mensuales, sujetas a disponibilidad, para eventos a
            desarrollarse en Camping BA desde Julio 2022 a Julio 2023) y a
            merchandising exclusivo de la marca Bud (campera, 2 vasos, una
            frapera y un beermat).
            <br />
            7.- Asignación y adjudicación de los Premios: El Participante que
            resulte seleccionado como potencial ganador será contactado por el
            Organizador para verificar el cumplimiento de los requisitos de
            participación vía mensaje directo, correo electrónico o
            telefónicamente. En dicho contacto, se le solicitará un correo
            electrónico, su nombre y apellido, número y foto de su DNI, fecha de
            nacimiento y un teléfono de contacto, domicilio y localidad. El
            potencial ganador deberá contestar correctamente al menos 4 de 5
            preguntas de cultura general que el Organizador le formulará.
            <br />
            8.- Entrega de Premios: Dentro de las 24 horas de la selección de
            los ganadores, el Organizador los contactará a efectos de coordinar
            la participación como telonero en el caso de quien haya grabado la
            canción más votada, por decisión unánime de los jurados y en el caso
            de quien haya sido seleccionado entre todos los votantes el envío
            del voucher (digital) alusivo al pase anual.
            <br />
            9.- Premio no asignados: La falta de reclamo de asignación del
            Premio en la forma y dentro de los plazos establecidos
            precedentemente, la falta de acreditación de la identidad del
            ganador, la imposibilidad de contactar al Ganador o el
            incumplimiento de cualquiera de los requisitos establecidos en estas
            Bases hará perder al Ganador, automáticamente, el derecho a la
            asignación del Premio, en cuyo caso se contactará al suplente. De
            persistir la falta de verificación del cumplimiento de los
            requisitos de asignación, el Premio será considerado como no
            asignado. El Premio no asignados quedará en propiedad y a
            disposición del Organizador.
            <br />
            10.- Gastos adicionales: Todo impuesto actual o futuro que grave los
            Premios objeto de la presente Promoción o todo gasto en que por
            cualquier concepto incurran los favorecidos en la presente Promoción
            para participar en la misma, así como para el traslado y otros
            gastos para reclamar o retirar o gozar del Premio, en su caso, serán
            a exclusivo cargo del adjudicatario del Premio.
            <br />
            11.- Responsabilidades: El Organizador no será responsable por los
            daños o perjuicios que pudiere sufrir el Ganador, o los terceros, o
            las personas que hagan uso del Premio con motivo o en ocasión de la
            participación en la presente Promoción o en ocasión de la
            utilización del Premio. La responsabilidad del Organizador
            finalizará con la entrega del Premio al Ganador, no siendo
            responsable por eventuales fallas en los sistemas informáticos o
            telefónicos dispuestos para la recepción de datos para participar,
            ni por el mal funcionamiento de los equipos de computación o de
            telefonía utilizados por los Participantes para el ingreso de los
            datos para participar.
            <br />
            12.- Suspensión, modificaciones, cancelación: El Organizador podrá
            ampliar la cantidad de premios ofrecidos o el plazo de vigencia de
            la Promoción. Cuando circunstancias no imputables al Organizador o
            no previstas en estas Bases o que constituyan caso fortuito o fuerza
            mayor lo justifiquen, el Organizador podrá suspender, cancelar o
            modificar la Promoción.
            <br />
            13.- Aceptación de Bases y Condiciones: Quienes participen en esta
            Promoción aceptan de pleno derecho todas y cada una de las
            disposiciones descriptas en estas Bases.
            <br />
            14.- Disponibilidad: Las presentes Bases podrán ser consultadas por
            cualquier interesado en www.budweiser.com.ar
            <br />
            15.- Autorización: Los Participantes autorizan expresamente al
            Organizador a captar y utilizar sus datos, nombres, números de
            documentos, imágenes personales, video y voces de estos últimos con
            fines publicitarios y promocionales en cualquier medio de
            comunicación, ya sea televisivo, cinematográfico, radial, gráfico,
            Internet, etc., en la forma en que el Organizador considere más
            conveniente y sin que esto genere derecho a compensación de ninguna
            especie, por un plazo de veinticuatro (24) meses contados desde la
            finalización del Plazo de Vigencia de la Promoción. .
            <br />
            El Organizador se reserva el derecho a realizar la acción
            publicitaria, de prensa, promoción, publicación y difusión que
            considere respecto de la Promoción y sus Participantes.
            <br />
            Con el hecho de participar en la Promoción, los Participantes
            prestan su expresa conformidad para la utilización y difusión de sus
            nombres, imagen, cuentas de Instagram, canción con la que
            participaron, así como el contenido de la publicación (reservándose
            el derecho de editar o modificar la misma) por el plazo de un año
            desde finalizada la Promoción, en el territorio de la República
            Argentina, y los medios publicitarios y de comunicación que el
            Organizador disponga. En virtud de esta autorización, los
            Participantes no tendrán derecho a indemnización, pago o
            contraprestación alguna por la difusión y/o utilización mencionada
            precedentemente.
            <br />
            16.- Datos personales: Los datos personales brindados por los
            Participantes para la participación en esta Promoción, serán objeto
            de tratamiento automatizado e incorporado a la base de datos de
            titularidad de Cervecería y Maltería Quilmes S.A.I.C.A. y G, con
            domicilio constituido a los efectos de la presente Promoción, en la
            calle Charcas 5160, Ciudad de Buenos Aires. La provisión de los
            datos por parte de los Participantes de la Promoción es voluntaria,
            sin perjuicio de ser un requisito necesario para participar en la
            Promoción.
            <br />
            El titular de los datos personales o sus representantes legales
            tienen la facultad de ejercer el derecho de acceso a los mismos en
            forma gratuita a intervalos no inferiores a seis meses, salvo que se
            acredite un interés legítimo al efecto conforme lo establecido en el
            artículo 14, inciso 3 de la Ley Nº 25.326.
            <br />
            Los Participantes podrán requerir que dicha información le sea
            suministrada, actualizada, rectificada o removida de la base de
            datos a su pedido. A tales efectos, el Participante podrá
            comunicarse al 0-800-222- 378392.
            <br />
            La Agencia de Acceso a la Información Pública, órgano de control de
            la Ley Nº 25.326 tiene la atribución de atender las denuncias y
            reclamos que se interpongan con relación al incumplimiento de las
            normas sobre protección de datos personales.
            <br />
            El registro y participación de la Promoción implicará el
            otorgamiento por parte del Participante, del consentimiento previsto
            en los artículos 5º y 11º de la Ley 25.326.
            <br />
            17.- Publicidad de los ganadores: Dentro de los diez (10) días
            hábiles siguientes a la finalización de la Promoción, se dará a
            publicidad sus nombres por parte del Organizador con la finalidad de
            informar el resultado de la Promoción.
            <br />
            18.- Ley aplicable y Jurisdicción: Toda relación que en virtud de la
            Promoción se genere entre el Participante y el Organizador será
            regida y concertada con total sujeción a las leyes de la República
            Argentina, renunciando el Participante a cualquier otra ley a cuya
            aplicación pudiera tener derecho. Para cualquier cuestión judicial
            que pudiera derivarse de la realización de la Promoción, los
            Participantes y el Organizador se someterán a la jurisdicción de los
            tribunales ordinarios competentes con asiento en la Ciudad de Buenos
            Aires o a cualquiera que se prevea en la legislación vigente.
            <br />
            BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.
          </p>
        </div>
      </Layout>

      {!isMobile && (
        <div className={styles.sectionFooter}>
          <SvgIcon name="bud-icon" size="8.901vw" className={styles.budIcon} />
          <p>BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.</p>
        </div>
      )}
    </div>
  )
}

export default TyC
